import * as React from "react"
import { footerStyle, copyright, blurb, logos } from "./footer.module.css"

export function Footer() {
  return (
    <footer className={footerStyle}>
      <div className={blurb}>
        <div className={logos}></div>
      </div>

      <div className={copyright}>
        Leftier {new Date().getFullYear()} · All rights reserved
        <p>4640 Cass St #99153 San Diego - CA 92169</p>
      </div>
    </footer>
  )
}
